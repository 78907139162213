import Player from "./Player.js";
import NowPlaying from "./NowPlaying";
import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import LineIcon from "react-lineicons";
import CircularProgress from '@mui/material/CircularProgress';
// import HomeIcon from './img/house-solid.svg';
// import VideoIcon from './img/video-solid.svg';
// import MicrophoneIcon from './img/microphone-solid.svg';
// import ListIcon from './img/list-solid.svg';
// import CalendarIcon from './img/calendar-days-solid.svg';
// import LinkIcon from './img/link-solid.svg';
// import FacebookIcon from './img/facebook-f.svg';
// import TwitterIcon from './img/twitter.svg';
// import AppleIcon from './img/apple.svg';
// import AndroidIcon from './img/android.svg';
// import CloseIcon from './img/xmark-solid.svg';
// import BarsIcon from './img/bars-solid.svg';
import { BsHouse } from "@react-icons/all-files/bs/BsHouse";
import { BsCameraVideo } from "@react-icons/all-files/bs/BsCameraVideo";
import { BsMic} from "@react-icons/all-files/bs/BsMic";
import { BsListUl } from "@react-icons/all-files/bs/BsListUl";
import { BsList } from "@react-icons/all-files/bs/BsList";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { BsCalendar } from "@react-icons/all-files/bs/BsCalendar";
import { BsLink } from "@react-icons/all-files/bs/BsLink";
import { AiOutlineFacebook } from "@react-icons/all-files/ai/AiOutlineFacebook";
import { RiTwitterLine } from "@react-icons/all-files/ri/RiTwitterLine";
import { AiOutlineApple } from "@react-icons/all-files/ai/AiOutlineApple";
import { AiOutlineAndroid } from "@react-icons/all-files/ai/AiOutlineAndroid";


const Home = React.lazy(() => import('./Home.js'));
const DJlist = React.lazy(() => import('./DJlist.js'));
const Shows = React.lazy(() => import('./Shows.js'));
const VisualRadio = React.lazy(() => import('./VisualRadio.js'));
const Playlist = React.lazy(() => import('./Playlist.js'));
const SinglePostCheck = React.lazy(() => import('./SinglePostCheck.js'));
const DJpage = React.lazy(() => import('./DJpage.js'));
const Partners = React.lazy(() => import('./Partners.js'));


const App = () => {

  const [navbarOpen, setNavbarOpen] = useState(false)


  const handleToggle = () => {
    setNavbarOpen(prev => !prev)
  }


  const loadInstagramEmbed = () => {
    const script = document.createElement("script");

    script.src = "https://www.instagram.com/static/bundles/es6/EmbedSDK.js/ab12745d93c5.js";
    script.defer = true;

    document.body.appendChild(script);
  

    script.addEventListener('load', () => {
    });

  }

  loadInstagramEmbed();
  

  return (
    <div className="App">


      <Router>
      <div className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
      <Suspense fallback={<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress /></div>}>
      <List className="mobileNavItems">
      <div onClick={handleToggle} className="closeToggle"><AiOutlineClose alt="Close" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/></div>
      <div className="menuInner">
      <ListItem>
      <Link to="/home" style={{fontSize: 14, marginBottom: 10}}><BsHouse alt="Home" fontSize={20} style={{marginRight: 15, marginBottom: -3}} />Home</Link>
      </ListItem>
      <ListItem>
      <Link to="/visual-radio" style={{fontSize: 14, marginBottom: 10}}><BsCameraVideo alt="Visual Radio" fontSize={20} style={{marginRight: 15, marginBottom: -3}} />Visual Radio</Link>
      </ListItem>
      <ListItem>
      <Link to="/DJs" style={{fontSize: 14, marginBottom: 10}}><BsMic alt="DJs" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>DJs</Link>
      </ListItem>
      <ListItem>
      <Link to="/playlist" style={{fontSize: 14, marginBottom: 10}}><BsListUl alt="Playlist" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Playlist</Link>
      </ListItem>
      <ListItem>
      <Link to="/uitzendingen" style={{fontSize: 14, marginBottom: 10}}><BsCalendar alt="Uitzendingen" fontSize={18} style={{marginRight: 15, marginBottom: -3}}/>Uitzendingen</Link>
      </ListItem>
      <ListItem>
      <Link to="/partners" style={{fontSize: 14, marginBottom: 10}}><BsLink alt="Partners" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Partners</Link>
      </ListItem>
      <ListItem>
      <a href="https://facebook.com/wearemegahit" style={{fontSize: 14, marginBottom: 10}}><AiOutlineFacebook alt="Facebook" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Facebook</a>
      </ListItem>
      <ListItem>
      <a href="https://twitter.com/megahitlive" style={{fontSize: 14, marginBottom: 10}}><RiTwitterLine alt="Twitter" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Twitter</a>
      </ListItem>
      <ListItem>
      <a href="https://apps.apple.com/app/id1518632994" style={{fontSize: 14, marginBottom: 10}}><AiOutlineApple alt="iOS" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>MegaHit voor iOS</a>
      </ListItem>
      <ListItem>
      <a href="https://play.google.com/store/apps/details?id=com.megahit" style={{fontSize: 14}}><AiOutlineAndroid alt="iOS" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>MegaHit voor Android</a>
      </ListItem>
      </div>
      </List>
      </Suspense>


      </div>
      <div className="nav1">
      <Suspense fallback={<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress /></div>}>
      <Link to="/home">
      <img
        className="station-logo"
        src="https://megahitlive.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/10/mhwebp-2.webp"
        alt="MegaHit"
        rel="preload"
      />
      </Link>
        <div className="mobilenav">
        <div onClick={handleToggle}><BsList alt="Home" fontSize={20} style={{marginRight: 15, marginTop: 20}} /></div>
      </div>
      </Suspense>


        <List className="nav-items">
        <Suspense fallback={<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress /></div>}>
      <ListItem>
      <Link to="/home" style={{fontSize: 14, marginBottom: 10}}><BsHouse alt="Home" fontSize={20} style={{marginRight: 15, marginBottom: -3}} />Home</Link>
      </ListItem>
      <ListItem>
      <Link to="/visual-radio" style={{fontSize: 14, marginBottom: 10}}><BsCameraVideo alt="Visual Radio" fontSize={20} style={{marginRight: 15, marginBottom: -3}} />Visual Radio</Link>
      </ListItem>
      <ListItem>
      <Link to="/DJs" style={{fontSize: 14, marginBottom: 10}}><BsMic alt="DJs" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>DJs</Link>
      </ListItem>
      <ListItem>
      <Link to="/playlist" style={{fontSize: 14, marginBottom: 10}}><BsListUl alt="Playlist" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Playlist</Link>
      </ListItem>
      <ListItem>
      <Link to="/uitzendingen" style={{fontSize: 14, marginBottom: 10}}><BsCalendar alt="Uitzendingen" fontSize={18} style={{marginRight: 15, marginBottom: -3}}/>Uitzendingen</Link>
      </ListItem>
      <ListItem>
      <Link to="/partners" style={{fontSize: 14, marginBottom: 10}}><BsLink alt="Partners" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Partners</Link>
      </ListItem>
      <ListItem>
      <a href="https://facebook.com/wearemegahit" style={{fontSize: 14, marginBottom: 10}}><AiOutlineFacebook alt="Facebook" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Facebook</a>
      </ListItem>
      <ListItem>
      <a href="https://twitter.com/megahitlive" style={{fontSize: 14, marginBottom: 10}}><RiTwitterLine alt="Twitter" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>Twitter</a>
      </ListItem>
      <ListItem>
      <a href="https://apps.apple.com/app/id1518632994" style={{fontSize: 14, marginBottom: 10}}><AiOutlineApple alt="iOS" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>MegaHit voor iOS</a>
      </ListItem>
      <ListItem>
      <a href="https://play.google.com/store/apps/details?id=com.megahit" style={{fontSize: 14}}><AiOutlineAndroid alt="iOS" fontSize={20} style={{marginRight: 15, marginBottom: -3}}/>MegaHit voor Android</a>
      </ListItem>

      </Suspense>
      </List>
      </div>
      
      <div className="view">
        <div className="pad">
        <Suspense fallback={<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress /></div>}>
        <Routes>
          <Route exact path="/uitzendingen" element={<Shows />}/>
          <Route exact path="/react" element={<Home />}/>
          <Route exact path="/DJs" element={<DJlist/>}/>
          <Route exact path="/uitzendingen" element={<Shows/>}/>
          <Route exact path="/visual-radio" element={<VisualRadio/>}/>
          <Route exact path="/playlist" element={<Playlist />}/>
          <Route path="/hotnews/:slug" element={<SinglePostCheck />}/>
          <Route path="/newmusic/:slug" element={<SinglePostCheck />}/>
          <Route exact path="/partners" element={<Partners slug="partners" />}/>
          <Route path="/djs/:slug" element={<DJpage />}/>
          <Route path="*" element={<Home />}/>
        </Routes>
        </Suspense>

      </div>
      </div>
      <div className="player">
      <div className="player_inner"><Player url="https://air.broadcastinggroup.ro/MegaHit" /></div>
      <Suspense fallback={<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress /></div>}>
       <NowPlaying />
      </Suspense>

      </div>
      </Router>

    </div>
  );
}

export default App;